<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-col w-full mb-2">
      <vs-row vs-type="flex" vs-justify="center" class="mb-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            {{
              $t(alerts.MsgAcquireMembership1.i18n) ||
                alerts.MsgAcquireMembership1.name
            }}
          </h2>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            <strong>{{
              $t(alerts.MsgAcquireMembership2.i18n) ||
                alerts.MsgAcquireMembership2.name
            }}</strong>
          </h2>
        </vs-col>
      </vs-row>
      <form-wizard
        ref="checkoutWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true"
      >
        <tab-content
          :title="$t(resources.Product.i18n) || resources.Product.name"
          icon="feather icon-shopping-bag"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full">
              <vx-card class="mb-base">
                <div class="vx-row mb-2">
                  <div class="vx-col sm:w-1/4 w-full">
                    <label>{{
                      $t(resources.Type.i18n) || resources.Type.name
                    }}</label>
                    <ul class="leftx">
                      <li>
                        <vs-radio
                          v-model="radioMembershipType"
                          vs-value="1"
                          v-on:change="deleteBilling()"
                          >{{
                            $t(resources.Distributor.i18n) ||
                              resources.Distributor.name
                          }}</vs-radio
                        >
                      </li>
                      <li>
                        <vs-radio
                          v-model="radioMembershipType"
                          vs-value="2"
                          v-on:change="deleteBilling()"
                          >{{
                            $t(resources.Customer.i18n) ||
                              resources.Customer.name
                          }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="vx-col md:w-1/3 w-full">
                    <label class="vs-input--label">{{
                      $t(resources.CountryOperation.i18n) ||
                        resources.CountryOperation.name
                    }}</label>
                    <v-select
                      v-model="countryOperationId"
                      :clearable="false"
                      :options="countryOperationType"
                      :reduce="data => data.countryId"
                      label="display"
                      v-on:input="getProductByType(`24`)"
                      scrollable
                    >
                      <template slot="option" slot-scope="option">
                        <img
                          :src="option.iconUrl"
                          @error="imageUrlAlt"
                          class="h-6 w-6"
                        />
                        {{ option.display }}
                      </template>
                    </v-select>
                  </div>
                  <!-- <div class="vx-col md:w-1/3 w-full">
                    <label class="vs-input--label">{{
                      $t(resources.Currency.i18n) || resources.Currency.name
                    }}</label>
                    <v-select
                      v-model="currencyId"
                      :clearable="false"
                      :options="currencyList"
                      :reduce="data => data.id"
                      label="name"
                      scrollable
                      v-on:input="getProductByType(`${currencyId}`)"
                      :hint="`${currencyId}`"
                    >
                    </v-select>
                  </div> -->
                  <div
                    class="vx-col md:w-1/3 w-full mt-4"
                    v-if="countryOperationId"
                  >
                    <label class="vs-input--label">{{
                      $t(resources.Product.i18n) || resources.Product.name
                    }}</label>
                    <v-select
                      v-model="productSelectId"
                      :clearable="false"
                      :options="productSelect"
                      :reduce="data => data.id"
                      label="name"
                      v-on:input="setProductMembership(`${productSelectId}`)"
                      :hint="`${productSelectId}`"
                    >
                      <template slot="option" slot-scope="option">
                        <img :src="option.imageUrl" class="h-8 w-9" />
                        {{ option.name }}
                      </template>
                    </v-select>
                  </div>
                  <div
                    class="vx-col md:w-1/2 w-full mt-base"
                    v-if="
                      radioMembershipType == '1' &&
                        currencyId == 24 &&
                        productSelectId == 16
                    "
                  >
                    <label class="">¿Adquirir plan con paquete de viaje?</label>
                    <v-select
                      v-model="groupId"
                      :clearable="false"
                      :options="groups"
                      :reduce="data => data.id"
                      label="name"
                      v-on:input="selectInstallment(`${groupId}`)"
                      :hint="`${groupId}`"
                    >
                    </v-select>
                  </div>
                  <!-- <div
                    class="vx-col md:w-1/2 w-full mt-base"
                    v-if="radioMembershipType == '1' && currencyId == 24"
                  >
                    <ul class="centerx">
                      <li>
                        <vs-checkbox
                          v-model="includeNeo"
                          v-on:change="deleteNeo()"
                          >
                          ¿Deseas incluir nuestro Seminario de fin de año ONLINE por sólo $15?
                          </vs-checkbox
                        >
                      </li>
                    </ul>
                  </div> -->
                </div>

                <div
                  class="vx-row"
                  v-if="isInstallmentsAllowed && radioMembershipType == '1' && groupId != '' && groupId != null"
                >
                  <vs-divider />
                  <div class="vx-col md:w-1/1 w-full">
                    <ul class="centerx">
                      <li>
                        <vs-checkbox v-model="checkBox1">{{
                          $t(resources.FinanceProduct.i18n) ||
                            resources.FinanceProduct.name
                        }}</vs-checkbox>
                      </li>
                    </ul>
                  </div>
                  <div class="vx-col md:w-1/1 w-full" v-if="checkBox1">
                    <label class="vs-input--label">{{
                      $t(resources.Installments.i18n) ||
                        resources.Installments.name
                    }}</label>
                    <v-select
                      v-model="installmentProductOptionId"
                      :clearable="false"
                      :options="installmentProductOption"
                      v-on:input="
                        setInstallment(`${installmentProductOptionId}`)
                      "
                      :hint="`${installmentProductOptionId}`"
                    ></v-select>
                  </div>
                </div>

                <vs-button
                  v-if="productSelectId || membershipId != 0"
                  class="mt-6 ml-auto flex"
                  @click="$refs.checkoutWizard.nextTab()"
                  >{{
                    $t(resources.Next.i18n) || resources.Next.name
                  }}</vs-button
                >
              </vx-card>
            </div>

            <!-- RIGHT COL: CONTINUE WITH SAVED ADDRESS -->
            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validateAffiliation()"
                    >{{
                      $t(resources.GenerateMembership.i18n) ||
                        resources.GenerateMembership.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
        <tab-content
          :title="
            $t(resources.BasicInformation.i18n) ||
              resources.BasicInformation.name
          "
          icon="feather icon-user"
          class="mb-2"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full mb-2">
              <vx-card>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-4">
                    <ul class="centerx">
                      <li>
                        <vs-checkbox v-model="isBusiness">{{
                          $t(resources.IsCompany.i18n) ||
                            resources.IsCompany.name
                        }}</vs-checkbox>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-2/3 w-full mb-2" v-if="isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="$t(resources.Name.i18n) || resources.Name.name"
                        v-model="name"
                        maxlength="32"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="name"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('name')"
                        >{{ errors.first("name") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-2/3 w-full" v-else>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/2 w-full mb-2">
                        <div class="vx-col w-full">
                          <vs-input
                            class="w-full"
                            icon-pack="feather"
                            icon="icon-user"
                            :label="
                              $t(resources.Name.i18n) || resources.Name.name
                            "
                            v-model="name"
                            maxlength="32"
                            v-validate="{
                              required: true,
                              regex: /^([a-zA-Z ]+)$/
                            }"
                            name="name"
                          />
                          <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first("name") }}</span
                          >
                        </div>
                      </div>
                      <div class="vx-col md:w-1/2 w-full mb-2">
                        <div class="vx-col w-full">
                          <vs-input
                            class="w-full"
                            icon-pack="feather"
                            icon="icon-user"
                            :label="
                              $t(resources.LastName.i18n) ||
                                resources.LastName.name
                            "
                            v-model="lastName"
                            maxlength="32"
                            v-validate="{
                              required: true,
                              regex: /^([a-zA-Z ]+)$/
                            }"
                            name="lastName"
                          />
                        </div>
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('lastName')"
                          >{{ errors.first("lastName") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="email"
                        v-validate="'required|max:100|email'"
                        name="email"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('email')"
                        >{{ errors.first("email") }}</span
                      >
                    </div>
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.DocumentType.i18n) ||
                        resources.DocumentType.name
                    }}</label>
                    <v-select
                      v-model="documentTypeId"
                      :clearable="false"
                      :options="identifiacionTypeList"
                      :reduce="data => data.id"
                      label="name"
                      v-validate="'required'"
                      name="documentTypeId"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentTypeId')"
                      >{{ errors.first("documentTypeId") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        v-if="documentTypeId == 11"
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="document"
                        name="document"
                        type="number"
                        v-validate="'required|min:8|max:9'"
                      />
                      <vs-input
                        v-else
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="document"
                        name="document"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('document')"
                      >{{ errors.first("document") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <vs-select
                      class="w-full"
                      icon-no-border
                      :label="
                        $t(resources.Gender.i18n) || resources.Gender.name
                      "
                      v-model="gender"
                      v-validate="'required'"
                      name="gender"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="$t(item.i18n) || item.text"
                        v-for="(item, index) in genderOption"
                      />
                    </vs-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('gender')"
                      >{{ errors.first("gender") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthDay.i18n) || resources.BirthDay.name
                    }}</label>
                    <v-select
                      v-model="birthDay"
                      :clearable="false"
                      :options="dayList"
                      :reduce="data => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthDay"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthDay')"
                      >{{ errors.first("birthDay") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthMonth.i18n) || resources.BirthMonth.name
                    }}</label>
                    <v-select
                      v-model="birthMonth"
                      :clearable="false"
                      :options="monthList"
                      :reduce="data => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthMonth"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthMonth')"
                      >{{ errors.first("birthMonth") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthYear.i18n) || resources.BirthYear.name
                    }}</label>
                    <v-select
                      v-model="birthYear"
                      :clearable="false"
                      :options="yearList"
                      :reduce="data => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthYear"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthYear')"
                      >{{ errors.first("birthYear") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.Indicative.i18n) || resources.Indicative.name
                    }}</label>
                    <v-select
                      v-model="indicativeId"
                      :clearable="false"
                      :options="countryList"
                      :reduce="country => country.id"
                      label="name"
                      v-validate="'required'"
                      name="indicativeId"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('indicativeId')"
                      >{{ errors.first("indicativeId") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-smartphone"
                        :label="
                          $t(resources.Mobile.i18n) || resources.Mobile.name
                        "
                        v-model="phone"
                        name="phone"
                        v-validate="'required|numeric'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('phone')"
                      >{{ errors.first("phone") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.ResidenceCountry.i18n) ||
                        resources.ResidenceCountry.name
                    }}</label>
                    <v-select
                      v-model="countryId"
                      :clearable="false"
                      :options="countryList"
                      :reduce="country => country.id"
                      label="name"
                      v-on:input="getStateByCountry(`${countryId}`)"
                      :hint="`${countryId}`"
                      name="countryId"
                      v-validate="'required'"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('countryId')"
                      >{{ errors.first("countryId") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.ResidenceState.i18n) ||
                        resources.ResidenceState.name
                    }}</label>
                    <v-select
                      v-model="stateId"
                      :clearable="false"
                      :options="stateCountry"
                      :reduce="state => state.id"
                      label="name"
                      v-on:input="getCityByState(`${stateId}`)"
                      :hint="`${stateId}`"
                      v-validate="'required'"
                      name="state"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('state')"
                      >{{ errors.first("state") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label c>{{
                      $t(resources.ResidenceCity.i18n) ||
                        resources.ResidenceCity.name
                    }}</label>
                    <v-select
                      v-model="cityId"
                      :clearable="false"
                      :options="destinationList"
                      :reduce="city => city.id"
                      label="name"
                      v-validate="'required'"
                      name="city"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('city')"
                      >{{ errors.first("city") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="address"
                        maxlength="99"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9- ]+)$/
                        }"
                        name="address"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('address')"
                      >{{ errors.first("address") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="zipCode"
                        v-validate="'required|min:4|max:10'"
                        name="zipCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCode')"
                      >{{ errors.first("zipCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryName.i18n) ||
                            resources.BeneficiaryName.name
                        "
                        v-model="beneficiaryName"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z ]+)$/
                        }"
                        name="beneficiaryName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryName')"
                      >{{ errors.first("beneficiaryName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryLastName.i18n) ||
                            resources.BeneficiaryLastName.name
                        "
                        v-model="beneficiaryLastName"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z ]+)$/
                        }"
                        name="beneficiaryLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryLastName')"
                      >{{ errors.first("beneficiaryLastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="!isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryName.i18n) ||
                            resources.BeneficiaryName.name
                        "
                        v-model="beneficiaryName"
                        v-validate="{
                          required: false,
                          regex: /^([a-zA-Z ]+)$/
                        }"
                        name="beneficiaryName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryName')"
                      >{{ errors.first("beneficiaryName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="!isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryLastName.i18n) ||
                            resources.BeneficiaryLastName.name
                        "
                        v-model="beneficiaryLastName"
                        v-validate="{
                          required: false,
                          regex: /^([a-zA-Z ]+)$/
                        }"
                        name="beneficiaryLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryLastName')"
                      >{{ errors.first("beneficiaryLastName") }}</span
                    >
                  </div>
                </div>

                <vs-divider>
                  <h5>
                    {{
                      $t(resources.BinaryTree.i18n) || resources.BinaryTree.name
                    }}
                  </h5>
                </vs-divider>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Sponsor.i18n) || resources.Sponsor.name
                      }}</label>
                      <v-select
                        id="sponsor"
                        @keyup.native="getItaByText('sponsor')"
                        v-model="sponsor"
                        :clearable="true"
                        :options="sponsorList"
                        :reduce="item => item.itaCode"
                        label="fullName"
                        name="sponsor"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('sponsor')"
                        >{{ errors.first("sponsor") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.BusinessCenter.i18n) ||
                          resources.BusinessCenter.name
                      }}</label>
                      <v-select
                        v-model="businessCenterId"
                        :clearable="false"
                        :options="businessCenterOption"
                        :reduce="data => data.value"
                        label="text"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Side.i18n) || resources.Side.name
                      }}</label>
                      <v-select
                        v-model="sideId"
                        :clearable="false"
                        :options="sideOption"
                        :reduce="data => data.value"
                        label="text"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Enroller.i18n) || resources.Enroller.name
                      }}</label>
                      <v-select
                        id="enroller"
                        @keyup.native="getItaByText('enroller')"
                        v-model="enroller"
                        :clearable="true"
                        :options="enrollerList"
                        :reduce="item => item.itaCode"
                        label="fullName"
                        name="enroller"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('enroller')"
                        >{{ errors.first("enroller") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Closer.i18n) || resources.Closer.name
                      }}</label>
                      <v-select
                        id="presenter"
                        @keyup.native="getItaByText('presenter')"
                        v-model="presenter"
                        :clearable="true"
                        :options="presenterList"
                        :reduce="item => item.itaCode"
                        label="fullName"
                        name="presenter"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('presenter')"
                        >{{ errors.first("presenter") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2 mt-5">
                    <div class="vx-col w-full">
                      <vs-checkbox v-model="isAllpresenter">{{
                        $t(resources.AllCloser.i18n) || resources.AllCloser.name
                      }}</vs-checkbox>
                    </div>
                  </div>
                </div>

                <div class="flex justify-between">
                  <vs-button
                    class="mt-6 ml-auto flex"
                    @click="$refs.checkoutWizard.prevTab()"
                    >{{
                      $t(resources.Back.i18n) || resources.Back.name
                    }}</vs-button
                  >
                  <vs-button
                    class="mt-6 ml-2 flex"
                    @click.prevent="validBasicInformation"
                    >{{
                      $t(resources.Next.i18n) || resources.Next.name
                    }}</vs-button
                  >
                </div>
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validateAffiliation()"
                    >{{
                      $t(resources.GenerateMembership.i18n) ||
                        resources.GenerateMembership.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
        <tab-content
          :title="
            $t(resources.PaymentMethod.i18n) || resources.PaymentMethod.name
          "
          icon="feather icon-credit-card"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full relative">
              <vx-card
                class="mb-2"
                :title="$t(resources.Select.i18n) || resources.Select.name"
              >
                <div class="vx-col md:w-1/2 w-full">
                  <label class="vs-input--label">{{
                    $t(resources.WayToPay.i18n) || resources.WayToPay.name
                  }}</label>
                  <v-select
                    v-model="wayToPayOptionId"
                    :clearable="true"
                    :options="wayToPayOption"
                    :reduce="data => data.wayToPayId"
                    label="name"
                    v-on:input="setWayToPay(`${wayToPayOptionId}`)"
                    :hint="`${wayToPayOptionId}`"
                  />
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.creditCard"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  -
                  {{
                    $t(resources.CreditCard.i18n) || resources.CreditCard.name
                  }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Type.i18n) || resources.Type.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.creditCardTypeId"
                        :clearable="false"
                        :options="creditcardOption"
                        :reduce="data => data.cardId"
                        label="name"
                        v-on:input="
                          setInstallmentNumber(
                            `${creditcardPayment.creditCardTypeId}`
                          )
                        "
                        :hint="`${creditcardPayment.creditCardTypeId}`"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-credit-card"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.CreditCardNumber.i18n) ||
                            resources.CreditCardNumber.name
                        "
                        v-model="creditcardPayment.cardNumber"
                        v-validate="'required|credit_card'"
                        name="cardNumber"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cardNumber')"
                        >{{ errors.first("cardNumber") }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="
                      installmentNumberOption &&
                        installmentNumberOption.length > 0
                    "
                  >
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.InstallmentNumber.i18n) ||
                          resources.InstallmentNumber.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.installmentNumber"
                        :clearable="false"
                        :options="installmentNumberOption"
                        :reduce="data => data.cardId"
                        label="name"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="password"
                        :maxlength="maxCvv"
                        :label="
                          $t(resources.SecurityCode.i18n) ||
                            resources.SecurityCode.name
                        "
                        v-model="creditcardPayment.cardSecurityCode"
                        name="SecurityCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('SecurityCode')"
                      >{{ errors.first("SecurityCode") }}</span
                    >
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label class="vs-input--label">{{
                      $t(resources.ExpirationYear.i18n) ||
                        resources.ExpirationYear.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationYear.i18n) ||
                          resources.ExpirationYear.name
                      "
                      v-model="expirationYear"
                      v-on:change="expirationYearChange($event)"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationYearOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label class="vs-input--label">{{
                      $t(resources.ExpirationMonth.i18n) ||
                        resources.ExpirationMonth.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationMonth.i18n) ||
                          resources.ExpirationMonth.name
                      "
                      v-model="expirationMonth"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationMonthOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.Names.i18n) || resources.Names.name
                        "
                        v-model="creditcardPayment.cardHolderName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardHolderName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardHolderName')"
                      >{{ errors.first("cardHolderName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.LastName.i18n) || resources.LastName.name
                        "
                        v-model="creditcardPayment.cardholderLastName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardholderLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardholderLastName')"
                      >{{ errors.first("cardholderLastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="creditcardPayment.zipCode"
                        v-validate="'required|min:5|max:10'"
                        name="zipCodeCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCodeCard')"
                      >{{ errors.first("zipCodeCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        size="small"
                        type="email"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="creditcardPayment.email"
                        v-validate="'required|max:100|email'"
                        name="emailCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('emailCard')"
                      >{{ errors.first("emailCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="creditcardPayment.address"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9 ]+)$/
                        }"
                        name="addressCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('addressCard')"
                      >{{ errors.first("addressCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="creditcardPayment.documentNumber"
                        v-validate="'required|max:20|alpha_num'"
                        name="documentCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="creditcardPayment.amount"
                        v-validate="'required|decimal:2|min_value:5'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.EWallet"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.EWallet.i18n) || resources.EWallet.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.Select.i18n) || resources.Select.name
                    }}</label>
                    <v-select
                      v-model="merchantId"
                      :clearable="false"
                      :options="walletOption"
                      :reduce="data => data.walletId"
                      label="name"
                      v-on:input="setAccountWallet(`${merchantId}`)"
                      :hint="`${merchantId}`"
                    ></v-select>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId == database.merchants.BlockPay"
                  >
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                        resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="accountWalletId"
                      :clearable="false"
                      :options="accountWalletOption"
                      :reduce="x => x.id"
                      label="name"
                      :hint="`${accountWalletId}`"
                      name="accountWalletId"
                      v-validate="'required'"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('accountWalletId')"
                      >{{ errors.first("accountWalletId") }}</span
                    >
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId == database.merchants.Payout"
                  >
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        type="number"
                        :label="
                          $t(resources.DistributorID.i18n) ||
                            resources.DistributorID.name
                        "
                        v-model="eWalletPayment.itaCodeWallet"
                        v-validate="'required'"
                        name="itaCodeWallet"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('itaCodeWallet')"
                      >{{ errors.first("itaCodeWallet") }}</span
                    >
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId != 0 && merchantId != ''"
                  >
                    <div
                      class="vx-col w-full"
                      v-if="merchantId == database.merchants.BlockPay"
                    >
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2|min_value:0.1'"
                        name="amountCard"
                      />
                    </div>
                    <div class="vx-col w-full" v-else>
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2|min_value:5'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col mb-2 mt-5" style="text-align: right">
                    <vs-button
                      color="primary"
                      type="filled"
                      @click.prevent="validPayment()"
                    >
                      {{ $t(resources.Save.i18n) || resources.Save.name }}
                    </vs-button>
                  </div>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.transfer"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.Transfer.i18n) || resources.Transfer.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BankName.i18n) || resources.BankName.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankTypeId"
                      :clearable="false"
                      :options="bankType"
                      :reduce="data => data.id"
                      label="name"
                      v-on:input="setAccountNumber(transferPayment.bankTypeId)"
                      :hint="transferPayment.bankTypeId"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                        resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankAccountId"
                      :clearable="false"
                      :options="accountNumberType"
                      :reduce="data => data.id"
                      label="account"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="transferPayment.documentId"
                        name="documentCard"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ReferenceCode.i18n) ||
                            resources.ReferenceCode.name
                        "
                        v-model="transferPayment.referenceCode"
                        name="referenceCode"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('referenceCode')"
                      >{{ errors.first("referenceCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="transferPayment.amount"
                        name="amountCard"
                        v-validate="'required|decimal:2|min_value:5'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="btn btn-primary" style="margin-right: 80px">{{
                      $t(resources.Attachment.i18n) || resources.Attachment.name
                    }}</label>
                    <vs-input
                      type="file"
                      class="w-full"
                      @change="onFileSelected"
                    />
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>

              <vx-card
                :title="
                  $t(resources.PaymentsReceived.i18n) ||
                    resources.PaymentsReceived.name
                "
              >
                <vs-table pagination max-items="10" :data="paymentList">
                  <template slot="thead">
                    <vs-th>{{
                      $t(resources.WayToPay.i18n) || resources.WayToPay.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Description.i18n) ||
                        resources.Description.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Value.i18n) || resources.Value.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.TransactionNumber.i18n) ||
                        resources.TransactionNumber.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.CreationDate.i18n) ||
                        resources.CreationDate.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Status.i18n) || resources.Status.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                    }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.transfer
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-download-cloud"
                          @click="
                            openDocument(
                              `${urlVision}payments/purchases/${data[indextr].attachmentId}.${data[indextr].attachmentExtension}`
                            )
                          "
                          >{{ data[indextr].merchantType }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].merchantType">{{
                        data[indextr].merchantType
                      }}</vs-td>
                      <vs-td :data="data[indextr].information">{{
                        data[indextr].information
                      }}</vs-td>
                      <vs-td :data="data[indextr].value">{{
                        data[indextr].value
                      }}</vs-td>
                      <vs-td :data="data[indextr].transactionNumber">{{
                        data[indextr].transactionNumber
                      }}</vs-td>
                      <vs-td :data="data[indextr].creationDate">{{
                        data[indextr].creationDate
                      }}</vs-td>
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.EWallet &&
                            data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-refresh-ccw"
                          @click="
                            paymentEwallet(
                              `${data[indextr].information}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                          >{{ data[indextr].stageName }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].stageName">{{
                        data[indextr].stageName
                      }}</vs-td>
                      <vs-td>
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-eye"
                          @click="
                            openResponseMerchant(
                              `${data[indextr].merchantResponse}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>

            <!-- RIGHT COL: CONTINUE WITH SAVED ADDRESS -->
            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validateAffiliation()"
                    >{{
                      $t(resources.GenerateMembership.i18n) ||
                        resources.GenerateMembership.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <vx-card
        :title="$t(resources.Management.i18n) || resources.Management.name"
        v-if="stageId != 0"
      >
        <h3 class="text-center text-primary mb-4">
          <strong
            >{{
              $t(resources.NewMember.i18n) || resources.NewMember.name
            }}:</strong
          >
          {{ newMemberCode }}&nbsp;|&nbsp;
          <strong
            >{{
              $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
            }}:</strong
          >
          {{ orderNumber }}
        </h3>
        <h3 class="text-center text-success">
          <strong
            >{{ $t(resources.Stage.i18n) || resources.Stage.name }}:</strong
          >
          {{ stageName }}
        </h3>
        <vs-row vs-type="flex" vs-justify="center" class="mb-base">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-mail"
              class="mt-4 ml-2"
              @click="sendWelcome()"
              >{{
                $t(resources.SendWelcome.i18n) || resources.SendWelcome.name
              }}</vs-button
            >
          </vs-col>
        </vs-row>
        <vs-tabs>
          <vs-tab
            :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
            icon-pack="feather"
            icon="icon-message-circle"
          >
            <comments
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
              :purchaseType="1"
            ></comments>
          </vs-tab>
          <vs-tab
            :label="$t(resources.Tracking.i18n) || t(resources.Tracking.name)"
            icon-pack="feather"
            icon="icon-list"
          >
            <tracking
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></tracking>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
    <vs-row vs-type="flex" vs-justify="flex-end">
      <!-- Descriptions -->
      <vs-button
        color="rgb(174,174,174)"
        type="filled"
        @click="$router.push('/purchases/index')"
        class="mt-2"
        >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
      >
    </vs-row>
    <vs-popup
      :title="$t(resources.Welcome.i18n) || resources.Welcome.i18n"
      :active.sync="popupWelcome"
    >
      <vs-row vs-type="flex" vs-justify="center" v-if="enabledButton">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img src="@/assets/images/logo/logoVT.png" width="450" class="mb-4" />
        </vs-col>
      </vs-row>

      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Hello.i18n) || resources.Hello.i18n }}
        {{ contactDto.name }},
      </h1>
      <h4 class="text-center mb-2">
        {{ $t(resources.MembershipJoin.i18n) || resources.MembershipJoin.i18n }}
      </h4>
      <h1 class="text-center text-success font-bold mb-base">
        {{ newMemberCode }}
      </h1>
      <h4 class="text-center mb-base">
        {{ $t(resources.OrderGenerated.i18n) || resources.OrderGenerated.i18n }}
        <strong>{{ orderNumber }}</strong>
      </h4>
      <h4 class="text-center mb-4">{{ msgSendContract }}</h4>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.i18n"
      :active.sync="popupResMer"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ responseMerchant }}</p>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import alerts from "@/i18n/alerts.js";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import billing from "@/views/purchases/billing.vue";
import { Validator } from "vee-validate";
import tracking from "../purchases/tracking.vue";
import comments from "../purchases/comments.vue";

export default {
  components: {
    vSelect,
    Datepicker,
    FormWizard,
    TabContent,
    billing,
    Validator,
    tracking,
    comments
  },
  data() {
    return {
      urlVision: process.env.VUE_APP_VISION,
      database: database,
      other: other,
      status: status,
      resources: resources,
      alerts: alerts,
      lang: lang,
      isNavOpen: false,
      popupWelcome: false,
      popupResMer: false,
      responseMerchant: "",
      document: "",
      name: "",
      lastName: "",
      address: "",
      email: "",
      phone: "",
      enroller: "",
      sponsor: "",
      presenter: "",
      sponsorIsDisabled: true,
      isAllpresenter: true,
      sponsorList: [],
      enrollerList: [],
      presenterList: [],
      sideId: 1,
      sideOption: [
        { value: 1, text: `${this.$i18n.t("Left")}` },
        { value: 2, text: `${this.$i18n.t("Right")}` }
      ],
      businessCenterOption: [
        { value: 1, text: `${this.$i18n.t("Center")} 1` },
        { value: 2, text: `${this.$i18n.t("Center")} 2` },
        { value: 3, text: `${this.$i18n.t("Center")} 3` }
      ],
      genderOption: [
        { value: 1, text: this.$i18n.t("Female") },
        { value: 2, text: this.$i18n.t("Male") },
        { value: 3, text: this.$i18n.t("Other") }
      ],
      businessCenterId: 1,
      gender: "",
      countryList: [],
      indicativeId: "",
      identifiacionTypeList: [],
      documentTypeId: "",
      countryId: "",
      stateCountry: [],
      stateId: "",
      destinationList: [],
      cityId: "",
      zipCode: "",
      beneficiaryName: "",
      beneficiaryLastName: "",
      language: this.$i18n.locale,
      languages: lang,
      birthDate: "",
      productList: [],
      countryOperationType: [],
      countryOperationId: 0,
      productSelect: [],
      productSelectId: 0,
      product: {},
      radioMembershipType: "1",
      contactDto: {},
      msgAcquireMembership: "",
      wayToPayOption: [],
      wayToPayOptionId: 0,
      countryDto: {},
      creditcardPayment: {},
      creditcardOption: [],
      installmentNumberType: [],
      bankType: [],
      installmentNumberOption: [],
      purchaseId: 0,
      expirationYearOption: [],
      expirationMonthOption: [],
      currentYear: 0,
      currentMonth: 0,
      expirationYear: 0,
      expirationMonth: 0,
      billingData: [],
      transferPayment: {},
      eWalletPayment: {},
      accountNumberType: [],
      maxCvv: 0,
      isInstallmentsAllowed: false,
      installmentProductOption: [],
      installmentProductOptionId: 1,
      checkBox1: false,
      includeNeo: false,
      productPrice: {},
      products: [],
      membershipId: 0,
      authCode: 0,
      paymentList: [],
      isGenerated: 0,
      stageId: 0,
      stageName: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      dayList: [],
      monthList: [],
      yearList: [],
      paymentConfirmed: 0,
      newMemberCode: 0,
      newMemberId: 0,
      orderNumber: 0,
      msgSendContract: "",
      stageList: [],
      merchantId: "",
      walletOption: [],
      accountWalletId: "",
      accountWalletOption: [],
      contactTypeId: 1,
      currencyId: 24,
      currencyCode: "",
      currencyList: [],
      currencyDto: {},
      isBusiness: false,
      groups: [
        // {
        //   id: 1,
        //   value: 3,
        //   name: "Paquete Madrid x2",
        //   price: 968,
        //   productId: 67
        // },
        // {
        //   id: 2,
        //   value: 3,
        //   name: "Paquete Madrid x1",
        //   price: 734,
        //   productId: 67
        // },
        {
          id: 3,
          value: 6,
          name: "Paquete Las Vegas x2",
          price: 2298.72,
          productId: 67
        },
        {
          id: 4,
          value: 6,
          name: "Paquete Las Vegas x1",
          price: 1324.36,
          productId: 67
        }
      ],
      groupId: ""
    };
  },
  computed: {
    enabledButton() {
      let totalPay = 0;
      let products = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );

      let _wayToPayTotal = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.wayToPay
      );

      for (let index = 0; index < products.length; index++) {
        totalPay +=
          (products[index].productValue + products[index].productTax) /
          this.installmentProductOptionId;
      }

      if (_wayToPayTotal.length > 0) {
        let _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );

        if (
          totalPay <= _payments &&
          this.isGenerated == 0 &&
          (this.stageId == this.status.genealogy.pending ||
            this.stageId == 0 ||
            this.stageId == this.status.genealogy.verify ||
            this.stageId == this.status.genealogy.binaryData)
        )
          return true;
        else return false;
      } else return false;
    }
  },
  methods: {
    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.currencyList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getIdentifiacionType() {
      let country = this.countryOperationType.find(
        x => x.countryId == this.countryOperationId
      );
      let countryCode = country.countryCode;

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/GetByCountry/${this.$i18n.locale.toUpperCase()}/${countryCode}/${countryCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            if (
              country.countryId == this.database.countryOperation.USA &&
              this.radioMembershipType == 1
            ) {
              this.identifiacionTypeList = result.data.filter(
                x => x.id == this.database.identificationType.SSN
              );
            } else {
              this.identifiacionTypeList = result.data;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.OV
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryOperationType = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProductByType(item) {
      this.clearProductInfo();

      this.contactTypeId =
        this.radioMembershipType == "1"
          ? this.database.contactType.Ita
          : this.database.contactType.ClientIta;

      this.countryDto = this.countryOperationType.filter(
        x => x.countryId == this.countryOperationId
      )[0];

      this.currencyDto = this.currencyList.filter(x => x.id == item)[0];

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/Prices/${this.$i18n.locale.toUpperCase()}/${
          this.countryDto.countryCode
        }/${this.currencyDto.code}/${this.contactTypeId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.products = result.data;
            if (this.radioMembershipType == this.database.contactType.ClientIta)
              this.productSelect = result.data.filter(
                x =>
                  x.code != "NEO001" &&
                  x.code != "PRM001" &&
                  x.code != "PRM003" &&
                  x.code != "UPVIVE03" &&
                  x.name.toLowerCase().indexOf("autoship")
              );
            else
              this.productSelect = result.data.filter(
                x =>
                  x.code != "NEO001" &&
                  x.code != "PRM001" &&
                  x.code != "PRM003" &&
                  x.code != "UPVIVE03" &&
                  x.name.toLowerCase().indexOf("autoship")
              );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      await this.getIdentifiacionType();
    },

    async getWayToPay() {
      let data = {
        applicationId: this.database.application.OV,
        countryCode: this.countryDto.countryCode,
        productId: this.product.id,
        supplierId: this.database.supplier.visionTravel,
        language: this.$i18n.locale.toUpperCase(),
        currencyCode: this.product.currencyCode,
        contactId: this.contactDto.id
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.wayToPayOption = [];
        }
      );
    },

    async createPayment() {
      this.$vs.loading();
      let _wayToPayTotal = 0;
      let _productTotal = 0;
      let _otherValue = 0;
      let _taxesValue = 0;
      if (this.billingData.length > 0) {
        if (
          this.billingData.filter(
            x => x.orderType == this.other.billingOrder.wayToPay
          ).length > 0
        ) {
          _wayToPayTotal = this.billingData
            .filter(x => x.orderType == this.other.billingOrder.wayToPay)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        if (
          this.billingData.filter(
            x => x.orderType == this.other.billingOrder.product
          ).length > 0
        ) {
          _productTotal = this.billingData
            .filter(x => x.orderType == this.other.billingOrder.product)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        _otherValue = this.productPrice.other;
        _taxesValue = this.productPrice.taxes;
      }

      let _data = {};
      let _payment = {};
      if (this.purchaseId) _data.purchaseId = this.purchaseId;
      else _data.purchaseId = 0;

      _data.language = this.$i18n.locale;
      _data.currencyCode = this.product.currencyCode;
      _data.countryCode = this.countryDto.countryCode;
      _data.contactId = this.contactDto.id;
      _data.itaCode = this.contactDto.itaCode;
      _data.applicationId = this.database.application.OV;
      _data.supplierId = this.database.supplier.visionTravel;
      _data.createdBy = this.contactDto.email;
      _data.purchaseTypeId = 1;
      _data.purchaseDetail = [];
      _data.wayToPayId = this.wayToPayOptionId;
      _data.rateValue = this.product.rateValue;
      _data.taxesValue = _taxesValue;
      _data.otherValue = _otherValue;
      _data.installment = this.installmentProductOptionId;

      let productList = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );
      for (let x = 0; x < productList.length; x++) {
        _data.purchaseDetail.push({ productId: productList[x].productId });
      }

      if (this.wayToPayOptionId == this.database.merchantType.creditCard) {
        _payment.creditCardTypeId = this.creditcardPayment.creditCardTypeId;
        _payment.cardNumber = this.creditcardPayment.cardNumber;
        _payment.securityCode = this.creditcardPayment.cardSecurityCode;
        _payment.documentNumber = this.creditcardPayment.documentNumber;
        _payment.name = this.creditcardPayment.cardHolderName;
        _payment.lastName = this.creditcardPayment.cardholderLastName;
        _payment.address = this.creditcardPayment.address;
        _payment.zipCode = this.creditcardPayment.zipCode;
        _payment.email = this.creditcardPayment.email;
        _payment.amount = this.creditcardPayment.amount;
        _payment.currencyCode = this.product.currencyCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _payment.transactionId = 0;
        _wayToPayTotal += this.creditcardPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
        if (
          this.creditcardPayment.installmentNumber &&
          this.creditcardPayment.installmentNumber != 0
        )
          this.installmentNumber = this.creditcardPayment.installmentNumber;
        else this.installmentNumber = 1;
        _payment.installmentNumber = this.installmentNumber;
      } else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.accountId = this.transferPayment.bankAccountId;
        _payment.documentId = this.transferPayment.documentId;
        _payment.referenceCode = this.transferPayment.referenceCode;
        _payment.amount = this.transferPayment.amount;
        _payment.currencyCode = this.product.currencyCode;
        _payment.language = this.$i18n.locale;
        _wayToPayTotal += this.transferPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
      } else if (this.wayToPayOptionId == this.database.merchantType.EWallet) {
        if (this.merchantId == this.database.merchants.Payout) {
          _payment.charge = false;
          _payment.userName = this.eWalletPayment.itaCodeWallet;
        } else if (this.merchantId == this.database.merchants.BlockPay) {
          _payment.charge = true;
          _payment.userName = this.accountWalletId;
          _payment.accountTypeId = this.accountWalletOption.find(
            x => x.id == this.accountWalletId
          ).typeAccountId;
        }

        _payment.WalletId = this.merchantId;
        _payment.amount = this.eWalletPayment.amount;
        _payment.language = this.$i18n.locale;
      }

      _data.payment = _payment;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/BuyProduct`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          await this.createRecord(result.data.data.purchaseId, true);
          this.purchaseId = result.data.data.purchaseId;

          if (!result.data.success || result.data.resultCode == 250) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else {
            if (!result.data.data.payResult.success) {
              this.$vs.notify({
                time: 6000,
                title: this.$i18n.t("Alert"),
                text: result.data.data.payResult.message,
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgPaymentMade"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            }
            this.authCode = result.data.data.payResult.allocationNumber;

            this.addPay(_data, result.data.data.payResult.success);
            if (this.wayToPayOptionId == this.database.merchantType.transfer)
              this.getuploadForm(
                result.data.data.purchaseId,
                result.data.data.paymentId
              );
            await this.getPayments();
            this.creditcardPayment = {};
            this.transferPayment = {};
            this.eWalletPayment = {};
            await this.validateAffiliation();
            this.$vs.loading.close();
          }
        },
        async error => {
          await this.createRecord(0, false);

          this.$vs.dialog({
            color: "danger",
            title: this.$i18n.t("Error"),
            text: error.response.data.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
      this.$vs.loading.close();
    },

    async getuploadForm(purchaseId, paymentId) {
      this.$vs.loading();
      let formData = new FormData();
      formData.set("purchaseId", purchaseId);
      formData.set("paymentId", paymentId);
      formData.set("language", this.$i18n.locale);
      formData.append(
        "file",
        this.transferPayment.file,
        this.transferPayment.file.Name
      );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/AddAttachment`,
        data: formData,
        headers: {
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
          "Content-Type": "multipart/form-data"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("MsgSaved"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async validateAffiliation() {
      this.$vs.loading();
      let _totalPay = 0;
      let _payments = 0;
      let products = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );

      for (let index = 0; index < products.length; index++) {
        _totalPay +=
          (products[index].productValue + products[index].productTax) /
          this.installmentProductOptionId;
      }

      let _wayToPayTotal = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.wayToPay
      );

      if (_wayToPayTotal.length > 0) {
        _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
      }

      if (this.paymentConfirmed >= _totalPay) {
        await this.validContact();
      } else if (_payments >= _totalPay) {
        await this.changeStage(this.status.genealogy.verify);
      }

      this.$vs.loading.close();
    },

    async generate() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/generate`,
        data: {
          purchaseId: this.purchaseId,
          createdBy: this.contactDto.email,
          email: this.email,
          membershipId: this.membershipId
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else if (result.data.success && result.data.code == 200) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message
            });
          } else {
            await this.getStage();
            let response = result.data.data;
            this.stageId = response.stageId;
            this.stageName = this.stageList.find(
              x => x.id == response.stageId
            ).name;
            this.newMemberCode = response.newMemberCode;
            this.newMemberId = response.newMemberId;
            this.orderNumber = response.orderNumber;
            this.isGenerated = 1;

            this.msgSendContract = this.$i18n
              .t("MsgSendContract")
              .replace("{0}", this.email)
              .replace("{1}", `${this.name} ${this.lastName}`);
            this.popupWelcome = true;
            await this.sendWelcome();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async validContact() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}ita/validContact/${this.email}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success && result.data.code == 200) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message
            });
          } else {
            await this.generate();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async expirationYearChange(year) {
      var yearSelect = year;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    async createRecord(purchaseId, save) {
      let _data = {};
      _data.isBusiness = this.isBusiness ? 1 : 0;
      _data.type = this.radioMembershipType;
      _data.purchaseId = purchaseId;
      _data.itaCode = this.contactDto.itaCode;
      _data.countryOperationId = this.countryOperationId;
      _data.cityId = this.cityId;
      _data.indicativeId = this.indicativeId;
      _data.documentType = this.documentTypeId;
      _data.document = this.document;
      _data.gender = this.gender;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.birthDate = `${this.birthYear}-${this.birthMonth}-${this.birthDay}`;
      _data.email = this.email;
      _data.address = this.address;
      _data.zipCode = this.zipCode;
      _data.phone = this.phone;
      _data.beneficiaryName = this.beneficiaryName;
      _data.beneficiaryLastName = this.beneficiaryLastName;
      _data.sponsor = this.sponsor;
      _data.center = this.businessCenterId;
      _data.sideId = this.sideId;
      _data.enroller = this.enroller;
      _data.presenter = this.presenter;
      _data.isAllpresenter = this.isAllpresenter ? 1 : 0;
      _data.installmentNumber = this.installmentProductOptionId;
      _data.createdBy = this.contactDto.email;

      await this.sendEmail(purchaseId, JSON.stringify(_data));
      if (save) {
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/create`,
          data: _data,
          headers: {
            "content-type": "application/json",
            "accept-language": this.$i18n.locale
          }
        }).then(
          result => {
            if (!result.data.success) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: result.data.message,
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            } else {
              this.membershipId = result.data.recordId;
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: result.data.message,
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getMembership() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.data.length == 0) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              accept: this.acceptDialog
            });
          } else {
            let response = result.data.data;
            let detail = response.detail.filter(
              x => x.productId == "OFA" //this.database.products.NEO001
            );

            this.includeNeo = detail.length > 0 ? true : false;
            this.radioMembershipType = response.type;
            this.isBusiness = response.isBusiness;
            this.countryId = response.countryId;
            this.countryOperationId = response.countryOperationId;
            this.currencyId = response.currencyId;
            await this.getProductByType(this.currencyId);

            await this.getStateByCountry(this.countryId);
            this.stateId = response.stateId;

            await this.getCityByState(this.stateId);

            this.productSelectId = response.productId;
            await this.setProductMembership(this.productSelectId);

            this.installmentProductOptionId = response.installmentNumber;
            if (response.installmentNumber > 1) {
              this.checkBox1 = true;
              this.setInstallment(this.installmentProductOptionId);
            }

            let _group = response.detail.filter(x => x.productCode == "GRP");
            if (_group.length > 0) {
              this.billingData.push({
                productId: _group[0].productId,
                productName: _group[0].productName,
                currencyCode: "USD",
                orderType: this.other.billingOrder.product,
                productValue: _group[0].value,
                productTax: 0,
                productCode: _group[0].productCode
              });
              this.groupId = this.groups.find(x => x.price == _group[0].value);
            }

            this.billingData.push({
              productId: 0,
              productName: this.$i18n.t("Payments"),
              currencyCode: this.product.currencyCode,
              orderType: this.other.billingOrder.wayToPay,
              productValue: response.totalPayment,
              productCode: this.product.code
            });

            this.membershipId = response.id;
            this.documentTypeId = response.identificationTypeId;
            this.document = response.identification;
            this.name = response.name;
            this.lastName = response.lastName;
            this.birthDay = response.birthDay;
            this.birthMonth = response.birthMonth;
            this.birthYear = response.birthYear;
            this.gender = response.gender;
            this.address = response.address;
            this.zipCode = response.zipCode;
            this.cityId = response.cityId;
            this.email = response.email;
            this.indicativeId = response.countryIndicativeId;
            this.phone = response.phone;
            this.beneficiaryName = response.beneficiaryName;
            this.beneficiaryLastName = response.beneficiaryLastName;
            this.enroller = response.enroller;
            this.sponsor = response.sponsorCode;
            this.businessCenterId = response.businessCenter;
            this.sideId = response.side;
            this.presenter = response.presenterCode;
            this.isAllpresenter = response.allPresenter == 1 ? true : false;
            this.paymentList = response.payment;
            this.isGenerated = response.isGenerated;
            this.stageId = response.stageId;
            this.stageName = response.stageName;
            this.paymentConfirmed = response.paymentConfirmed;
            this.newMemberCode = response.newMemberCode;
            this.newMemberId = response.newMemberId;
            this.orderNumber = response.orderNumber;

            if (
              response.newMemberCode != "" &&
              response.newMemberCode != 0 &&
              response.newMemberCode != null
            ) {
              this.msgSendContract = this.$i18n
                .t("MsgSendContract")
                .replace("{0}", this.email)
                .replace("{1}", `${this.name} ${this.lastName}`);
              this.popupWelcome = true;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getPayments() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data != null) {
            this.paymentList = result.data.data;
            let _total = 0;
            for (let index = 0; index < this.paymentList.length; index++) {
              if (this.paymentList[index].stageId == this.status.payment.paid) {
                _total =
                  parseFloat(this.paymentConfirmed) +
                  parseFloat(this.paymentList[index].value);
              }
            }
            this.paymentConfirmed = _total;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendHelloSign() {
      this.$vs.loading();

      await axios({
        method: "GET",
        url: `${
          this.url.helloSign
        }membership/sendForm/${this.$i18n.locale.toUpperCase()}/${
          this.purchaseId
        }`,
        headers: {
          "content-type": "application/json"
        }
      }).then(async result => {
        if (result.data.success) {
          let message = this.$i18n
            .t("MsgSendContract")
            .replace("{0}", this.email)
            .replace("{1}", `${this.name} ${this.lastName}`);

          this.$vs.dialog({
            color: "success",
            title: this.$i18n.t("Success"),
            text: message,
            accept: this.acceptDialog
          });
        }
      });
      this.$vs.loading.close();
    },

    async changeStage(newStageId) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}Tracking/createByPurchase`,
        data: {
          identifier: this.purchaseId,
          entityId: this.other.entity.purchase,
          previousStageId: this.status.genealogy.pending,
          newStageId: newStageId,
          createdBy: this.contactDto.email
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`
        }
      }).then(
        async result => {
          if (result.data.status == 200 || result.data.affectedRows == 1) {
            await this.getMembership();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getItaByText(origin) {
      let getElementId;
      if (origin == "sponsor")
        getElementId = document.getElementById("sponsor");
      else if (origin == "enroller")
        getElementId = document.getElementById("enroller");
      else if (origin == "presenter")
        getElementId = document.getElementById("presenter");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_OVAPI
          }Contact/getByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("loggedIn")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.sponsorList = [];
            } else {
              if (origin == "sponsor") this.sponsorList = result.data;
              else if (origin == "enroller") this.enrollerList = result.data;
              else if (origin == "presenter") this.presenterList = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async updateRecord() {
      this.$vs.loading();
      let _data = {};

      _data.id = this.membershipId;
      _data.type = this.radioMembershipType;
      _data.purchaseId = this.purchaseId;
      _data.itaCode = this.contactDto.itaCode;
      _data.countryOperationId = this.countryOperationId;
      _data.cityId = this.cityId;
      _data.indicativeId = this.indicativeId;
      _data.documentType = this.documentTypeId;
      _data.document = this.document;
      _data.gender = this.gender;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.birthDate = `${this.birthYear}-${this.birthMonth}-${this.birthDay}`;
      _data.email = this.email;
      _data.address = this.address;
      _data.zipCode = this.zipCode;
      _data.phone = this.phone;
      _data.beneficiaryName = this.beneficiaryName;
      _data.beneficiaryLastName = this.beneficiaryLastName;
      _data.sponsor = this.sponsor;
      _data.center = this.businessCenterId;
      _data.sideId = this.sideId;
      _data.enroller = this.enroller;
      _data.presenter = this.presenter;
      _data.isAllpresenter = this.isAllpresenter ? 1 : 0;
      _data.installmentNumber = this.installmentProductOptionId;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/update`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.membershipId = result.data.insertId;
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );

      this.$vs.loading.close();
    },

    async paymentEwallet(itaCode, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/validStatus/${itaCode}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getMembership();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async sendWelcome() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/Welcome`,
        data: {
          language: this.$i18n.locale.toUpperCase(),
          purchaseId: this.purchaseId,
          itaCode: this.newMemberCode,
          contactId: this.newMemberId,
          isIta: true
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgEmailSent"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_OVAPI
        }Stage/${this.$i18n.locale.toUpperCase()}/4`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageList = [];
          } else {
            this.stageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendEmail(purchaseId, json) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}NeoTool/Invitation`,
        data: {
          itaCode: this.contactDto.itaCode,
          url: `https://foca.visiontravel.net/purchases/membership/${purchaseId}`,
          urltext: this.$i18n.t("View"),
          subject: `[${this.$i18n.t("Membership")}] ITA ${
            this.contactDto.itaCode
          }`,
          message: `${json}`,
          language: this.$i18n.locale.toUpperCase(),
          guestList: [
            "a.gomez@visiontravel.net",
            "v.hernandez@visiontravelmail.net",
            "d.lopez@visiontravelmail.net",
            "odalis@visiontravel.net"
          ]
        },
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenVT")
        }
      });
    },

    //#region [ Not Async Functions ]
    acceptDialog() {
      this.$router.push({ path: "/purchases/index" });
    },

    addPay(item, statusPayment) {
      let _merchantType = this.wayToPayOption.filter(
        x => x.wayToPayId == item.wayToPayId
      );

      this.billingData.push({
        productId: this.product.id,
        productName: _merchantType[0].name,
        currencyCode: this.product.currencyCode,
        orderType: statusPayment
          ? this.other.billingOrder.wayToPay
          : this.other.billingOrder.notValidPayment,
        productValue: item.payment.amount,
        productCode: this.product.code
      });
    },

    deleteBilling() {
      this.installmentProductOptionId = 1;
      this.billingData = [];
      this.productSelectId = "";
      this.countryOperationId = "";
      this.currencyId = "24";
    },

    deleteNeo() {
      if (
        this.includeNeo &&
        this.currencyId == 24 &&
        this.billingData.filter(x => x.productCode == "OFA").length == 0
      ) {
        this.billingData.push({
          productId: 73,
          productName: "ONLINE - Seminario",
          currencyCode: this.product.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: 15,
          productTax: 0,
          productCode: "OFA"
        });
      } else if (!this.includeNeo) {
        this.billingData.splice(2, 1);
      }

      // if (
      //   this.includeNeo &&
      //   this.billingData.filter((x) => x.productCode == "NEO001").length == 0
      // ) {
      //   var neo = this.products.find((x) => x.code == "NEO001");
      //   this.billingData.push({
      //     productId: neo.id,
      //     productName: neo.name,
      //     currencyCode: neo.currencyCode,
      //     orderType: this.other.billingOrder.product,
      //     productValue: neo.installments.find(
      //       (x) => x.number == this.installmentProductOptionId
      //     ).price,
      //     productTax: neo.installments.find(
      //       (x) => x.number == this.installmentProductOptionId
      //     ).other,
      //     productCode: neo.code,
      //   });
      // } else if (!this.includeNeo) {
      //   this.billingData.splice(2, 1);
      // }
    },

    getBirtDate() {
      let dates = lang[this.$i18n.locale];
      let year = new Date().getFullYear() - 18;

      for (let index = 1; index < 32; index++) {
        this.dayList.push({
          value: index < 10 ? `0${index}` : index,
          text: index < 10 ? `0${index}` : index
        });
      }

      for (let index = 0; index < dates._months.length; index++) {
        let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
        this.monthList.push({ value: value, text: dates._months[index] });
      }

      for (let index = 0; index < 81; index++) {
        this.yearList.push({ value: year - index, text: year - index });
      }
    },

    imageUrlAlt(event) {
      event.target.src =
        "https://cdn1.visiontravel.net/Images/products/VIVE001.png";
    },

    openDocument(url) {
      window.open(url, this.$i18n.t("Transfer"));
    },

    onFileSelected(event) {
      this.transferPayment.file = event.target.files[0];
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter(x => x.id == item)
        .map(x => x.accounts);
      this.accountNumberType = _account[0].map(x => x);
    },

    setInstallment(item) {
      let _installment = this.product.installments.find(x => x.number == item);

      this.productPrice = _installment;
      this.billingData = [
        {
          productId: this.product.id,
          productName: this.product.name,
          currencyCode: this.product.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: _installment.price,
          productCode: this.product.code,
          productTax: _installment.other,
          imageUrl: this.product.imageUrl
        }
      ];
      if (this.radioMembershipType == "1") {
        var membership = this.products.find(x => x.code == "PRM001");
        //var neo = this.products.find((x) => x.code == "NEO001");
        this.billingData.push({
          productId: membership.id,
          productName: membership.name,
          currencyCode: membership.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: membership.installments.find(x => x.number == item)
            .price,
          productTax: membership.installments.find(x => x.number == item).other,
          productCode: membership.code
        });

        if (
          this.checkBox1 &&
          (this.purchaseId == null || this.purchaseId == 0)
        ) {
          let _group = this.groups.find(x => x.id == this.groupId);

          this.billingData.push({
            productId: _group.productId,
            productName: _group.name,
            currencyCode: "USD",
            orderType: this.other.billingOrder.product,
            productValue: _group.price,
            productTax: 0,
            productCode: "GRP"
          });
        }

        if (this.includeNeo) {
          this.billingData.push({
            productId: 73,
            productName: "ONLINE - Seminario",
            currencyCode: "USD",
            orderType: this.other.billingOrder.product,
            productValue: 15,
            productTax: 0,
            productCode: "OFA"
          });
          // this.billingData.push({
          //   productId: neo.id,
          //   productName: neo.name,
          //   currencyCode: neo.currencyCode,
          //   orderType: this.other.billingOrder.product,
          //   productValue: neo.installments.find((x) => x.number == item).price,
          //   productTax: neo.installments.find((x) => x.number == item).other,
          //   productCode: neo.code,
          // });
        }
      }
    },

    selectInstallment(groupId) {
      let _installment = this.groups.find(x => x.id == groupId).value;
      this.installmentProductOption = this.installmentProductOption.filter(
        x => x.number == _installment
      );
      this.installmentProductOptionId = _installment;
      this.checkBox1 = true;
      this.setInstallment(_installment);
    },

    setProductMembership(item) {
      this.installmentProductOptionId = 1;
      let _product = this.productSelect.filter(x => x.id == item);
      this.product = _product[0];
      if (_product != null) {
        let _installment = this.product.installments[0];

        this.billingData = [
          {
            productId: this.product.id,
            productName: this.product.name,
            currencyCode: this.product.currencyCode,
            orderType: this.other.billingOrder.product,
            productValue: _installment.price,
            productTax: _installment.other,
            productCode: this.product.code,
            imageUrl: this.product.imageUrl
          }
        ];

        if (this.radioMembershipType == "1") {
          var membership = this.products.find(x => x.code == "PRM001");
          //var neo = this.products.find((x) => x.code == "NEO001");
          this.billingData.push({
            productId: membership.id,
            productName: membership.name,
            currencyCode: membership.currencyCode,
            orderType: this.other.billingOrder.product,
            productValue: membership.installments.find(
              x => x.number == this.installmentProductOptionId
            ).price,
            productTax: membership.installments.find(
              x => x.number == this.installmentProductOptionId
            ).other,
            productCode: membership.code
          });
          if (this.includeNeo) {
            this.billingData.push({
              productId: 73,
              productName: "ONLINE - Seminario",
              currencyCode: "USD",
              orderType: this.other.billingOrder.product,
              productValue: 15,
              productTax: 0,
              productCode: "OFA"
            });
            // this.billingData.push({
            //   productId: neo.id,
            //   productName: neo.name,
            //   currencyCode: neo.currencyCode,
            //   orderType: this.other.billingOrder.product,
            //   productValue: neo.installments.find(
            //     (x) => x.number == this.installmentProductOptionId
            //   ).price,
            //   productTax: neo.installments.find(
            //     (x) => x.number == this.installmentProductOptionId
            //   ).other,
            //   productCode: neo.code,
            // });
          }
        }
        this.productPrice = _installment;

        if (this.product.installmentsAllowed.length > 1) {
          this.isInstallmentsAllowed = true;
          this.installmentProductOption = this.product.installmentsAllowed;
        } else {
          this.isInstallmentsAllowed = false;
          this.installmentProductOption = [];
        }

        this.getWayToPay();
      }
    },

    setContact() {
      this.contactDto = JSON.parse(localStorage.getItem("userInfo"));
    },

    setInstallmentNumber(item) {
      let _creditcard = this.creditcardOption.filter(x => x.cardId == item);
      this.maxCvv = _creditcard[0].maxCvv;
      this.installmentNumberOption = _creditcard[0].installmentNumber;
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayOption.filter(x => x.wayToPayId == item);
      if (item == 1)
        this.walletOption = _merchantType[0].options[0].wallets.map(x => x);
      if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map(x => x);
      if (item == 3)
        this.creditcardOption = _merchantType[0].options.map(x => x);
    },

    setAccountWallet(item) {
      let _accounts = this.walletOption.find(x => x.walletId == item).accounts;
      this.accountWalletOption = _accounts;
    },

    validBasicInformation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (
            this.membershipId != 0 &&
            this.membershipId != null &&
            this.membershipId != "" &&
            (this.stageId == this.status.genealogy.pending ||
              this.stageId == this.status.genealogy.binaryData ||
              this.stageId == 0)
          ) {
            await this.updateRecord();
          }
          this.$refs.checkoutWizard.nextTab();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    validPayment() {
      this.$validator.validateAll().then(result => {
        if (
          this.wayToPayOptionId == this.database.merchantType.transfer &&
          (this.transferPayment.file == null || this.transferPayment.file == "")
        ) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgNotAttachment"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        } else if (!result) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        } else {
          this.createPayment();
        }
      });
    },

    openResponseMerchant(text) {
      this.responseMerchant = text;
      this.popupResMer = true;
    },

    clearProductInfo() {
      this.productSelectId = "";
      this.productSelect = [];
      this.products = [];
      this.installmentProductOptionId = 1;
      this.billingData = [];
    }
    //#end region [Not async functions]
  },

  async created() {
    this.$vs.loading();

    this.purchaseId = this.$route.params.purchaseId;
    await this.getBirtDate();
    await this.getCountry();
    await this.getCurrency();
    await this.getCountryOperation();
    await this.setContact();
    await this.getYearsCrediCard();

    if (this.purchaseId != undefined && this.purchaseId != 0) {
      await this.getMembership();
    }
    this.$emit("init");
    this.$vs.loading.close();
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
